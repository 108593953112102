/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";


/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
*{
    font-family: poppins;
    font-weight: 900 !important;
}
img,ion-img {
    object-fit: cover !important;
}
ion-popover .popover-viewport {
    display: contents;
}
fa-icon {
    margin: 10px;
    color: #616e7e;
}

@mixin capitalize-first-letter($class) {
    .#{$class} {
      // Capitalize the first letter using CSS text-transform
      text-transform: capitalize !important;
    }
  }
  @mixin capitalizeFirstLetter {
    &:first-letter {
      text-transform: capitalize;
    }
  }

.custom-button-ok {
    background-color: black !important;
    color: white !important;
  }
  
  .custom-button-pay {
    background-color: black !important;
    color: white !important;
  }

.flagstyle{
    width:20px !important;
    height:20px !important;
  }
p{
    font-size: 14px !important;
}

ion-label {
    font-size: 16px !important;
}

ion-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    overflow: auto;
}

.slide-wrapper {
    width: 100%;
}
.mat-progress-bar {
    display: block;
    height: 10px !important;
    overflow: hidden;
    position: relative;
    transition: opacity 250ms linear;
    width: 100%;
    border-radius: 10px !important;
}

// styles.scss or component-specific SCSS file
.color-20 ::ng-deep .mat-progress-bar-fill::after {
    background: #eb445a !important;
}
.color-50 ::ng-deep .mat-progress-bar-fill::after {
    background: #ffc409 !important;
}
.color-80 ::ng-deep .mat-progress-bar-fill::after {
    background: #ffa500 !important;
}
.color-90 ::ng-deep .mat-progress-bar-fill::after {
    background: #3880ff !important;
}
.color-100 ::ng-deep .mat-progress-bar-fill::after {
    background: #2dd36f !important;
}


span.error {
    font-size: 16px;
    margin-top: 8px;
    margin-left: 8px;
    color: var(--ion-color-danger);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;

    ion-icon {
        margin-right: 4px;
    }
}

p {
    font-size: 14px !important;
}

ion-popover.lg {
    --min-width: 80%;
}


.fc {
    height: 501px;

    // height: 100%;
    a {
        color: #000000 !important;
    }

    .fc-header-toolbar {
        margin: 10px;

        .fc-toolbar-title {
            font-size: 16px !important;
        }

        .fc-button {
            padding: 5px !important;
            font-size: 14px !important;
        }

        // button {
        //     background: none;
        //     border: none;
        //     color: #000000;
        // }
        // .fc-button-primary:hover {
        //     background: none !important;
        //     color: #000000 !important;
        // }
        // .fc-button-primary:active {
        //     background: none !important;
        //     color: #000000 !important;
        // }
    }

    .fc-timegrid-slot {
        font-size: 16px !important;
    }

    .fc-col-header-cell-cushion {
        font-size: 16px !important;
    }
}

.link {
    text-decoration: none;
}

.align-center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.options-popover {
    --width: 80% !important;
    --max-height: 400px !important;
}

.divider {
    border-right: 1px solid var(--ion-color-step-150, #d7d8da);
    margin: 0px 6px;
}


.delay {
    --background: #ffffff;
}

.end {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}

.strike-out {
    text-decoration: line-through;
}

/* Custom Skeleton Line Height and Margin */
.fakeItem {

    h2,
    h3,
    p {
        background-color: lightgrey;
        opacity: 0.5;
        height: 1em;
        margin-top: 10px;
    }

    h2 {
        width: 35%;
    }

    h3 {
        width: 40%;
    }

    p {
        width: 60%;
    }
}

.swiper-scrollbar {
    display: none;
}

#item-search {
    .searchbar-input.sc-ion-searchbar-md {
        padding-left: 36px !important;
    }

    .searchbar-search-icon.sc-ion-searchbar-md {
        display: none !important;
    }
}

.ml-8 {
    margin-left: 8px;
}

.searchbar-clear-button.sc-ion-searchbar-md {
    display: none !important;
}

::ng-deep {
    .item {
        .img {
            border-radius: 7px !important;
        }
    }
}

:host {
    --min-height: 200px; /* Adjust the minimum height */
    --min-width: 300px;  /* Adjust the minimum width */
  }
  
  .ion-modal {
    --height: 200px;    /* Set the height of the modal */
    --width: 200px;     /* Set the width of the modal */
    --border-radius: 10px; /* Optional: round the corners */
  }



ion-title{
    font-weight: bold !important;
}


::ng-deep .picker-prefix, .picker-suffix, .picker-opt.picker-opt-selected {
    color: var(--ion-color-primary, #3880ff);
    font-weight: bold !important;
}

::ng-deep.swiper-slide,.swiper-zoom-container{
    text-align: start !important;
}

::ng-deep  .picker-opt.picker-opt-selected {
    font-weight: bold !important;
}

ion-thumbnail {
    --border-radius: 7px;
    border: 1px solid var(--grayColor);

}


::ng-deep h6 {
    font-size: 14px !important;
    margin: 5px !important;
}
h6 {
    font-size: 14px !important;
    margin: 5px !important;
}

#cart-option .popover-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.customer-list {
    width: 300px !important;
    height: 300px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.qty-numbers {
    position: absolute;
    padding: 2px;
    top: -8px;
    right: -5px;
    background-color: #2dd36f;
    border-radius: 50%;
    color: #ffffff;
    font-size: 10px;
    width: 20px;
    height: 20px;
    line-height: 13px;
    text-align: center;
}


@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(11, 11, 11, 0.54) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #111110 !important;
}





#myProgress::-webkit-progress-value {
    background-color: #4CAF50;
    /* Set the color of the progress bar */
}


.yellow .action-sheet-icon {
    color: yellow !important
  }
  
  .blue .action-sheet-icon {
    color: blue !important
  }
  .red .action-sheet-icon {
    color: red !important
  }
  
  .green .action-sheet-icon {
    color: green !important;
  }

  .overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(223, 210, 210, 0.6);
    z-index: 99999;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}


.centered-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; /* Set your desired width */
    height: 250px; /* Set your desired height */
  }

  .alert-radio-label.sc-ion-alert-md {
     padding: 30px !important; 
}

::ng-deep .alert-radio-label.sc-ion-alert-md {
    padding: 30px !important; 
}

.mt4{
    margin-top: 10px !important;
    border: none;
}

.promotion {
    font-size: 12px !important;
    background: var(--ion-color-warning, #ffc409);
    color: white;
    padding: 1px 5px 1px 5px;
    border-radius: 5px;
    margin: 10px;
    white-space: nowrap;
    &.pro{
        margin:0;
    }
}
